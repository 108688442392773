@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --primary-black: #1B1B1B;
    --primary-red: #FF0000;
    --primary-gold: #F0C60C;
}

* {
    color: var(--primary-black);
    font-size: 14px;
    font-family: 'MyanmarSansPro', sans-serif;
    box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body 
{
    position: relative;
    padding: 0;
    margin: 0;
}

.page 
{
    width: 100%;
    height: calc(100vh - 0px);
    position: relative;
    background-color: white;
}

label
{
    cursor: pointer;
}

.input-required::after {
    content: '*';
    color: red;

}

.x-input 
{
    width: 100%;
    height: 50px;
    padding: 13px 32px;
    font-size: 16px;
    background: #FFFFFF;
    border: 1px solid rgba(27, 27, 27, 0.4);
    border-radius: 5px;
}

.x-input-checkbox
{
    width: 25px;
    height: 25px;
    background: #FFFFFF;
    border: 1px solid rgba(27, 27, 27, 0.4);
    border-radius: 10px;
}

.x-label {
    margin-bottom: 15px;
    font-size: 15px;
    color: var(--primary-black);
    display: block;
}

.x-label-checkbox {
    margin-left: 15px;
    font-size: 15px;
    color: var(--primary-black);
}


.x-input:focus {
    outline: none;
    border: 1px solid var(--primary-black);
}

a {
    text-decoration: none;
}

.x-checkbox {
    width: 18px;
    height: 18px;
    cursor: pointer;
    
}

/* .form-btn-group {
    position: fixed;
    bottom: 0;
    right: 80px;
    padding-bottom: 30px;
} */