.invoice-details {
    padding-left: 2rem;
    padding-right: 2rem;
}

.invoice-details > div {
    display: flex;
    justify-content: space-between;
    padding-left: 1.25rem; /* 20px */
    padding-right: 1.25rem; /* 20px */
    padding-top: 0.75rem; /* 12px */
    padding-bottom: 0.75rem; /* 12px */
}

.invoice-details input[type="number"] {
    border: 1px solid #000;
    border-color: rgba(0, 0, 0, 0.2);
    border-radius: 0.25rem/* 4px */;
    width: 6rem;
}

.invoice-details input[type="text"]:focus{
    outline: none;
}

.invoice-details input[type="radio"] {
    accent-color: #000;
}

.invoice-details input[type="checkbox"] {
    position: relative;
    top: 2px;
    accent-color: #000;
    right: 3.5rem;
    accent-color: '#000';
}


